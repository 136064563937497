import { createContext, FC, ReactNode, useReducer } from 'react'
import { Action, State } from '@cpms/common/custom'
import { DropdownOption } from '@cpms/common/src/models'

/**
 * This context provider is deprecated
 * Last few bits of state should be removed as tech debt
 */

declare const process: {
  env: {
    REACT_APP_SYS_REF_DATA: string
    REACT_APP_BASE_API_URL: string
    NODE_ENV: string
  }
}

const studyTypes: DropdownOption[] = [
  {
    id: 0,
    name: '',
  },
  {
    id: 1,
    name: 'Commercial',
  },
  {
    id: 2,
    name: 'Non-commercial',
  },
]

export const initialState: State = {
  form: {
    studyTypes,
    studyId: 0,
    studyTypeId: 0,
    services: '',
    agreed: false,
    leadAdministrationId: 0,
    irasId: 0,
    title: '',
    shortTitle: '',
  },
  index: 0,
  error: false,
  message: '',
  dispatch: () => {},
}

const StoreContext = createContext<State>(initialState)
const storeReducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'FORM':
      return { ...state, form: { ...state.form, ...action.payload } }

    case 'RESET':
      return { ...state, form: { ...state.form, ...action.payload } }

    case 'ERROR':
      return {
        ...state,
        message: action.payload.message,
        error: action.payload.error,
      }

    default:
      return {
        ...state,
        message: 'Unknown action',
        error: true,
      }
  }
}

const Provider: FC<{ initialState?: State; children: ReactNode }> = ({
  initialState: initialStateContext = initialState,
  ...rest
}) => {
  const [state, dispatch] = useReducer(storeReducer, initialStateContext)

  return (
    <StoreContext.Provider
      value={{
        form: state.form,
        index: state.index,
        dispatch,
      }}
      {...rest}
    />
  )
}

export { Provider, StoreContext }
