import './Hero.styles.scss'

const Hero = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className='hero bg-blue-dark'>
      <div className='hero-message'>
        <div>{children}</div>
      </div>
      <div className='hero-image' />
    </div>
  )
}

export default Hero
