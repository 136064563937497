import { useContext, useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import { LiveMessage } from 'react-aria-live'
import { Helmet } from 'react-helmet'

import { useErrorSummary } from '@cpms/common/context/ErrorSummaryContext'
import Card from '@cpms/common/components/Card'
import BottomController from '@cpms/common/components/BottomController'
import { ErrorMessageWithFocus } from '@cpms/common/components/ErrorMessage'
import { DropDown } from '@cpms/common/components/Forms'
import { queryOptions } from '@cpms/common/config/settings'
import settings from '@app/config/settings'
import { StoreContext } from '../context/Provider'
import { fetchSysRefUkCountry } from '../requests/SysRef'

const LeadAdminstration = () => {
  const { form, dispatch } = useContext(StoreContext)
  const [inputValue, setInputValue] = useState<number>(form.leadAdministrationId)
  const [error, setError] = useState<string>()
  const history = useHistory()
  const errorMessages = useErrorSummary()

  useEffect(() => {
    // If any persisted data is lost, restart the application
    if (form.studyTypeId === 0) {
      history.push('/guidance')
    }
  }, [form.studyTypeId])

  const { data: nations } = useQuery('SysRefUkCountry', fetchSysRefUkCountry, queryOptions)

  const handleChange = (choice: any) => {
    setInputValue(choice)
    setError(undefined)
  }

  // ref hooks
  const inputRef = useRef(null)
  const errorRef = useRef(null)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (inputValue === 0) {
      setError('Invalid selection')
      errorMessages.dispatch({ type: 'focus' })
    } else {
      setError(undefined)
      // @ts-ignore
      dispatch({ type: 'FORM', payload: { leadAdministrationId: Number.parseInt(inputValue) } })
      history.push('/available-services')
    }
  }

  return (
    <>
      <Helmet title={`${settings.appTitle} - Select lead administration`} />
      <section ref={errorRef}>
        <LiveMessage
          aria-live='assertive'
          message={
            'Apply for a service for a new study - Select the administration where the Chief Investigator and/ or the Lead R&D office is located. If you are not sure which administration to select, please contact us at supportmystudy@n i h r.ac.uk'
          }
          clearOnUnmount
        />
        <ErrorMessageWithFocus
          errors={{
            error: !!error,
            message: 'You must select an option for the following questions before you continue:',
            label: 'Select Lead administration',
            description: '',
            link: inputRef,
          }}
        />
        <form onSubmit={handleSubmit}>
          <Card>
            <h1>Select lead administration</h1>
            <p>Select the administration where the Chief Investigator and/ or the Lead R&D office is located.</p>

            <DropDown
              setValue={handleChange}
              name='selectLeadAdministration'
              id='selectLeadAdministration'
              label='Select lead administration'
              options={nations}
              error={error}
              data-testid='selectLeadAdminstrationDropDown'
              value={inputValue}
              ref={inputRef}
              parentDivClassName='drop-down-card'
            />
            <div className='if-you-are-not-sure'>
              If you are not sure which administration to select, please contact us at
              <a href='mailto:supportmystudy@nihr.ac.uk' className='referral' rel='noreferrer'>
                supportmystudy@nihr.ac.uk
              </a>
            </div>
          </Card>
          <BottomController />
        </form>
      </section>
    </>
  )
}

export default LeadAdminstration
