import { Helmet } from 'react-helmet'
import Banner from '@cpms/common/components/Banner/Banner'
import PermissionToView from '@cpms/common/components/PermissionToView'
import RichText from '@cpms/common/components/RichText'
import Card from '@cpms/common/components/Card'
import settings from '@app/config/settings'
import Hero from '../components/Hero/Hero'
import Entries from '../config/entries'
import Screens from '../config/screens'
import useContentful from '../hooks/useContentful'

const Home = () => {
  const [items, isLoading] = useContentful(Screens.SIM_HOME_SCREEN)

  const hasNewsContent = !isLoading && items[Entries.SIM_HOME_NEWS_BANNER]
  const hasWarningContent = !isLoading && items[Entries.SIM_HOME_WARNING_BANNER]

  return (
    <>
      <Helmet title={`${settings.appTitle} - Home`} />

      <Banner
        active={hasWarningContent}
        heading={hasWarningContent && items[Entries.SIM_HOME_WARNING_BANNER].fields.bannerTitle}
        type='error'
        data-testid='alertContent'
      >
        <RichText
          richTextDocument={hasWarningContent && items[Entries.SIM_HOME_WARNING_BANNER].fields.bannerBody}
        ></RichText>
      </Banner>

      <Banner
        active={hasNewsContent}
        heading={hasNewsContent && items[Entries.SIM_HOME_NEWS_BANNER].fields.bannerTitle}
        type='info'
        data-testid='warningMsg'
      >
        <RichText richTextDocument={hasNewsContent && items[Entries.SIM_HOME_NEWS_BANNER].fields.bannerBody}></RichText>
      </Banner>

      <Hero>
        <>
          {isLoading && <h1>Loading...</h1>}
          {!isLoading && (
            <RichText richTextDocument={items[Entries.SIM_HOME_GUIDANCE_TEXT]?.fields.richText}></RichText>
          )}
        </>
      </Hero>

      <PermissionToView action='applyForService'>
        <Card className='flex justify-end'>
          <a href='/guidance' className='nhsuk-button mb-0' data-testid='applyButton'>
            {!isLoading && (items[Entries.SIM_HOME_APPLY_BUTTON_TEXT]?.fields.shortText || 'Loading...')}
          </a>
        </Card>
      </PermissionToView>
    </>
  )
}

export default Home
