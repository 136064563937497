enum Screens {
  // SoECAT Screens
  GUIDANCE_SCREEN,
  // SIM Screens
  SIM_GUIDANCE_SCREEN,
  SIM_CREATE_STUDY_CONTACT_SCREEN,
  SIM_CREATE_STUDY_NON_CONTACT_SCREEN,
  SIM_SELECT_STUDY_TYPE,
  SIM_CREATE_STUDY_HELPER_TEXT,
  SIM_AVAILABLE_SERVICES_NPA_AGREEMENT_RICH_SCREEN,
  SIM_MANAGE_STUDY_CONTACTS_SCREEN,
  SIM_HOME_SCREEN,
  SIM_AVAILABLE_SERVICES_SCREEN,
}

export default Screens
