import { Roles, User } from '../../../models/Auth'

export const applyForService = (user: User) => {
  if (user.Roles.includes(Roles.PublicUser)) {
    return true
  }
  if (user.Roles.includes(Roles.System)) {
    return true
  }
  if (user.Roles.includes(Roles.CompanyRepresentative) && user.Roles.includes(Roles.ICTViewer)) {
    return true
  }
  return false
}

export default applyForService
