import { Helmet } from 'react-helmet'
import { LiveMessage } from 'react-aria-live'
import Card from '@cpms/common/components/Card'
import BottomController from '@cpms/common/components/BottomController'
import RichText from '@cpms/common/components/RichText'
import settings from '@app/config/settings'
import Entries from '../config/entries'
import Screens from '../config/screens'
import useContentful from '../hooks/useContentful'

const Guidance = ({ history }: any) => {
  const [items, isLoading] = useContentful(Screens.SIM_GUIDANCE_SCREEN)
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    history.push('/study-type')
  }

  return (
    <>
      <Helmet title={`${settings.appTitle} - Guidance`} />

      <form onSubmit={handleSubmit}>
        <LiveMessage aria-live='assertive' message={'Apply for a service for a new study - Guidance'} clearOnUnmount />
        <Card>
          {isLoading && <h1>Loading...</h1>}
          {!isLoading && (
            <RichText richTextDocument={items[Entries.SIM_GUIDANCE_SCREEN_RICH_TEXT]?.fields.richText}></RichText>
          )}
        </Card>
        <BottomController />
      </form>
    </>
  )
}

export default Guidance
