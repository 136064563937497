import Entries from './entries'

const screenNameMapping: any = {
  [Entries.GUIDANCE_SCREEN_RICH_TEXT]: 'soecatUqGuidanceText',
  [Entries.SIM_GUIDANCE_SCREEN_RICH_TEXT]: 'simUqGuidanceText',
  [Entries.SIM_CREATE_STUDY_CONTACT_RICH_TEXT]: 'simCreateStudyContactScreen',
  [Entries.SIM_CREATE_STUDY_NON_CONTACT_RICH_TEXT]: 'simCreateStudyNonContact',
  [Entries.SIM_SELECT_STUDY_TYPE_COMMERCIAL_LABEL]: 'simUqSelectStudyTypeCommercial',
  [Entries.SIM_SELECT_STUDY_TYPE_NON_COMMERCIAL_LABEL]: 'simUqSelectStudyTypeNonCommercial',
  [Entries.SIM_CREATE_STUDY_HELPER_TEXT_RICH_TEXT]: 'sim81HelperText',
  [Entries.SIM_AVAILABLE_SERVICES_SOECAT_GUIDANCE_TEXT]: 'simAvailableServicesSoecatDescriptionText',
  [Entries.SIM_AVAILABLE_SERVICES_NPA_GUIDANCE_TEXT]: 'simAvailableServicesNpaDescriptionText',
  [Entries.SIM_AVAILABLE_SERVICES_NPA_AGREEMENT_RICH_TEXT]: 'simAvailableServicesNPAAgreementScreen',
  [Entries.SIM_MANAGE_STUDY_CONTACTS_GUIDANCE_TEXT]: 'simManageStudyContactsGuidanceText',
  [Entries.SIM_HOME_GUIDANCE_TEXT]: 'simHomeScreenGuidanceText',
  [Entries.SIM_HOME_APPLY_BUTTON_TEXT]: 'simHomeScreenApplyButtonText',
  [Entries.SIM_HOME_NEWS_BANNER]: 'simHomeScreenNewsBanner',
  [Entries.SIM_HOME_WARNING_BANNER]: 'simHomeScreenWarningBanner',
}

export default screenNameMapping
