import { ReactElement } from 'react'
import { useSession } from '../../context/AuthProvider'
import { Roles, User } from '../../models/Auth'
import applyForService from './permissionHelpers/applyForService'
import isAcordSpecialist from './permissionHelpers/isAcordSpecialist'
import isStudyRepresentative from './permissionHelpers/isStudyRepresentative'
import viewChangeComments from './permissionHelpers/viewChangeComments'
import isStudyRepresentativeAndAccordSpecialist from './permissionHelpers/isStudyRepresentativeAndAccordSpecialist'

interface PermissionToViewProps {
  action:
    | 'applyForService'
    | 'isAcordSpecialist'
    | 'isStudyRepresentative'
    | 'viewChangeComments'
    | Roles.StudyRepresentativeAndAccordSpecialist
  children: ReactElement
}

const actionHelpers: Record<string, (user: User) => boolean> = {
  applyForService,
  isAcordSpecialist,
  isStudyRepresentative,
  viewChangeComments,
  isStudyRepresentativeAndAccordSpecialist,
}

const checkPermission = (action: PermissionToViewProps['action'], user: User) => {
  const location = window.location.href
  const queryParams = new URLSearchParams(location)
  const isPreviousPathServiceApplications = queryParams.get('pp') !== 'sd'

  if (!action.includes(Roles.StudyRepresentativeAndAccordSpecialist)) {
    return actionHelpers[action](user)
  } else if (
    action.includes(Roles.StudyRepresentativeAndAccordSpecialist) &&
    actionHelpers['isStudyRepresentative'](user) &&
    isPreviousPathServiceApplications
  ) {
    return false
  }
  return true
}

const PermissionToView = ({ action, children }: PermissionToViewProps) => {
  const [{ user }] = useSession()
  return checkPermission(action, user) ? children : null
}

/**
 * Functional counterpart for inline conditionals
 */
export const permissionToView = (action: PermissionToViewProps['action'], { user }: { user: User }) => {
  return checkPermission(action, user)
}

export default PermissionToView
