import { LiveMessage } from 'react-aria-live'
import { Helmet } from 'react-helmet'
import BottomController from '@cpms/common/components/BottomController'
import Card from '@cpms/common/components/Card'
import { ErrorMessageWithFocus, ErrorProps } from '@cpms/common/components/ErrorMessage'
import { Input } from '@cpms/common/components/Forms'
import { queryOptions } from '@cpms/common/config/settings'
import { ValidationError } from '@cpms/common/custom'
import { api } from '@cpms/common/helpers/api-axios'
import { useErrorSummary } from '@cpms/common/context/ErrorSummaryContext'
import { useHistory } from 'react-router-dom'

import { createStudySubmission } from '@app/requests/StudySubmission'
import { FC, useContext, useEffect, useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { CreateStudySubmissionRequest } from '@app/models/StudySubmissionRequest.interface'
import { Entries } from '@app/config'
import Screens from '../config/screens'
import settings, { initialErrors, services } from '../config/settings'
import { StoreContext } from '../context/Provider'
import useContentful from '../hooks/useContentful'
import { fetchSysRefUkCountry } from '../requests/SysRef'

type CreateNewStudyValidationErrors = {
  error?: boolean
  shortTitle?: ValidationError
  fullName?: ValidationError
  IRASID?: ValidationError
}

declare let process: {
  env: {
    REACT_APP_CRN_REDIRECT: string
    REACT_APP_SOECAT_REDIRECT: string
    REACT_APP_BASE_API_URL: string
    REACT_APP_82_SUBMIT_FORM: string
  }
}

// the main event
const CreateNewStudy: FC = () => {
  const history = useHistory()
  const { form } = useContext(StoreContext)
  const [shortTitle, setShortName] = useState<string>('')
  const [FullName, setFullName] = useState<string>('')
  const [IRASID, setIRASID] = useState<string>('')
  const [errors, setErrors] = useState<ErrorProps>({ ...initialErrors })
  const [validations, setValidations] = useState<CreateNewStudyValidationErrors>({})
  const errorMessages = useErrorSummary()

  const { data: nations } = useQuery('SysRefUkCountry', fetchSysRefUkCountry, queryOptions)
  const createStudySubmissionMutation = useMutation((payload: CreateStudySubmissionRequest) =>
    createStudySubmission(payload),
  )

  useEffect(() => {
    // If any persisted data is lost, restart the application
    if (form.studyTypeId === 0) {
      history.push('/guidance')
    }
  }, [form.studyTypeId])

  // loading
  const [inProgress, setProgress] = useState<boolean>(false)

  // contentful
  const [nonContact, isNonLoading] = useContentful(Screens.SIM_CREATE_STUDY_NON_CONTACT_SCREEN)
  const [contact, isLoading] = useContentful(Screens.SIM_CREATE_STUDY_CONTACT_SCREEN)
  const [helperText, isHelperLoading] = useContentful(Screens.SIM_CREATE_STUDY_HELPER_TEXT)

  // ref hooks
  const errorRef = useRef(null)

  // multiple refs
  const shortTitleRef = useRef(null)
  const irasIdRef = useRef(null)
  const fullNameRef = useRef(null)

  // shift focus to error message
  const focusInputFields = (refName: string) => {
    // @ts-ignore
    refName && refs[refName]?.current?.scrollIntoView({ behavior: 'smooth' })
    // @ts-ignore
    refName && refs[refName]?.current?.focus()
  }

  // shift focus to error message
  const focusErrorMessage = () => {
    // @ts-ignore
    errorRef?.current?.scrollIntoView({ behavior: 'smooth' })
  }

  // handlers
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (handleValidation()) {
      focusErrorMessage()

      return false
    }
    submitForm()
  }

  const handleValidation = () => {
    const err: CreateNewStudyValidationErrors = {}
    let isError = false

    if (shortTitle.trim() === '' || shortTitle.length > 100) {
      err.shortTitle = {
        label: 'Enter a study short title (Maximum 100 Characters).',
        name: 'shortTitle',
        error: true,
        link: shortTitleRef,
      }
      isError = true
    }

    if (FullName.trim() === '' || FullName.trim().length > 4000) {
      err.fullName = {
        label: 'Enter a study title (Maximum 4000 Characters).',
        name: 'FullName',
        error: true,
        link: fullNameRef,
      }
      isError = true
    }

    if (
      !IRASID ||
      IRASID.toString().trim().length > 7 ||
      IRASID.toString().trim().length < 5 ||
      !/^-?\d+$/.test(IRASID.toString().trim())
    ) {
      err.IRASID = {
        label: 'Enter a valid IRAS ID (five to seven numeric characters, no spaces)',
        name: 'IRASID',
        error: true,
        link: irasIdRef,
      }
      isError = true
    }

    setValidations({ ...err, error: isError })
    setErrors({
      ...errors,
      error: isError,
      multipleErrors: Object.values(err) as ValidationError[],
      message: 'You must complete the following fields to continue:',
    })
    errorMessages.dispatch({ type: 'focus' })

    return isError
  }

  // handle form submit
  const submitForm = () => {
    errorMessages.dispatch({
      type: 'clear',
    })
    setProgress(true)
    api
      .post(`${process.env.REACT_APP_BASE_API_URL}/${process.env.REACT_APP_82_SUBMIT_FORM}`, {
        irasId: IRASID.trim(),
        title: FullName,
        shortTitle,
        leadAdministrationId: form.leadAdministrationId,
        studyTypeId: form.studyTypeId,
        serviceTypeId: Number(form.services),
      })
      .then((res) => {
        setProgress(false)
        handleResponse(res.data)
        return true
      })
      .catch((e) => {
        setProgress(false)
        setErrors({ ...errors, error: true, label: '', message: 'Encounter error while connecting to server' })
        focusErrorMessage()
        return false
      })
  }

  // handle server response
  const handleResponse = (res: any) => {
    if (res.StudyId !== undefined) {
      handleRedirect(form.services, res.StudyId)
    } else if (res.ErrorCode === 'USER_IS_CONTACT') {
      focusErrorMessage()
      setErrors({
        error: true,
        message: 'A study with the same IRAS ID exists in CPMS',
        label: 'IRAS ID',
        description: isLoading ? 'Loading...' : contact[Entries.SIM_CREATE_STUDY_CONTACT_RICH_TEXT].fields.richText,
        name: 'IRASID',
        link: irasIdRef,
      })
    } else if (res.ErrorCode === 'USER_IS_NOT_CONTACT') {
      focusErrorMessage()
      setErrors({
        error: true,
        message: 'A study with the same IRAS ID exists in CPMS',
        label: 'IRAS ID',
        description: isNonLoading
          ? 'loading'
          : nonContact[Entries.SIM_CREATE_STUDY_NON_CONTACT_RICH_TEXT].fields.richText,
        name: 'IRASID',
        link: irasIdRef,
      })
    }
  }

  // take care of successful redirection
  const handleRedirect = (condition: string, studyId: number) => {
    if (condition === services.SOECAT_SERVICE_INPUT_VALUE) {
      window.location.href = `${process.env.REACT_APP_SOECAT_REDIRECT}/complete-soecat?studyId=${studyId}`
    } else if (condition === services.NPA_SERVICE_INPUT_VALUE) {
      createStudySubmissionMutation.mutate(
        {
          studyId,
        },
        {
          onSuccess: ({ data }) => {
            window.location.href = `${process.env.REACT_APP_CRN_REDIRECT}/${data.studySubmissionId}`
          },
          onError: (err) => {},
        },
      )
    } else {
      console.error('redirecting failed: missing conditions')
    }
  }

  return (
    <>
      <Helmet title={`${settings.appTitle} - Add study information`} />

      <section ref={errorRef}>
        <LiveMessage
          aria-live='assertive'
          message={'Apply for a service for a new study - Provide the following information about your study:'}
          clearOnUnmount
        />
        <ErrorMessageWithFocus errors={errors} />
        <form onSubmit={handleSubmit} noValidate>
          <Card>
            <h1>Add study information</h1>

            <p>Provide the following information about your study:</p>
            <div>
              <Input
                label='Study short title'
                setValue={setShortName}
                value={shortTitle}
                error={validations.shortTitle?.error}
                name='shortStudyTitle'
                id='shortStudyTitle'
                data-testid='shortStudyTitle'
                description='Usually an abbreviation or acronym. Maximum of 100 characters.'
                maxLength={200}
                ref={shortTitleRef}
                required
                autoComplete='off'
              />
              <Input
                label='Study title'
                setValue={setFullName}
                value={FullName}
                error={validations.fullName?.error}
                name='fullName'
                id='fullName'
                data-testid='fullName'
                description={'The full title of the study as it appears on the protocol Maximum of 4000 characters.'}
                maxLength={4000}
                ref={fullNameRef}
                required
                autoComplete='off'
                textarea
              />
              <Input
                label='IRAS ID'
                setValue={setIRASID}
                value={IRASID}
                error={validations.IRASID?.error}
                name='irasID'
                id='irasID'
                data-testid='irasID'
                description={
                  isHelperLoading
                    ? 'Provide an IRAS ID, this is a study identifier used across the UK research landscape, including for studies which do not require ethical  review by the HRA. If you have not yet created an IRAS ID for this study, do so via https://www.myresearchproject.org.uk/help/hlpirasid.aspx'
                    : helperText[Entries.SIM_CREATE_STUDY_HELPER_TEXT_RICH_TEXT].fields.richText
                }
                ref={irasIdRef}
                required
                autoComplete='off'
              />
            </div>

            <div className='info-card'>
              <h1>Pre-populated study details</h1>
              <p>
                The following information about your study has been pulled from your previous answers about the study.
                These cannot change unless you cancel your application and start again.
              </p>
              <div>
                <Input
                  label='Lead administration'
                  description=''
                  value={nations && nations.find((nation) => nation.id === form.leadAdministrationId)?.description}
                  setValue={() => {}}
                  readOnly={true}
                  required={false}
                  name='leadAdminstration'
                  id='leadAdminstration'
                  data-testid='leadAdminstration'
                />
                <Input
                  label='Study type'
                  description=''
                  value={form.studyTypes[form.studyTypeId].name || ''}
                  setValue={() => {}}
                  readOnly={true}
                  required={false}
                  name='studyType'
                  id='studyType'
                  data-testid='studyType'
                />
              </div>
            </div>
          </Card>
          <BottomController progress={inProgress} />
        </form>
      </section>
    </>
  )
}

export default CreateNewStudy
