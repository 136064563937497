import { Suspense, lazy } from 'react'
import { useQuery } from 'react-query'
import { Helmet } from 'react-helmet'
import queryString from 'query-string'
import Card from '@cpms/common/components/Card'
import Heading from '@cpms/common/components/Heading'
import Footer from '@cpms/common/components/Footer/index'
import Error from '@cpms/common/components/Error'
import Loader from '@cpms/common/components/Loader'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import AuthService from '@cpms/common/services/AuthService'
import { useSession, getLogoutUrl } from '@cpms/common/context/AuthProvider'
import Header from '@cpms/common/components/Header/Header'
import { Layout, Container } from '@cpms/common/components/Layout'
import { queryOptions } from '@cpms/common/config/settings'
import { getMenu } from '@cpms/common/requests/Menu'
import { ErrorSummaryProvider } from '@cpms/common/context/ErrorSummaryContext'
import Banner from '@cpms/common/components/Banner/Banner'

import Breadcrumbs from './components/Breadcrumbs'
import AvailableServices from './pages/AvailableServices'
import CreateNewStudy from './pages/CreateNewStudy'
import Guidance from './pages/Guidance'
import Home from './pages/Home'
import LeadAdminstration from './pages/LeadAdminstration'
import ServiceStudy from './pages/StudyType'
import settings from './config/settings'
import './styles/app.scss'

const Dashboard = lazy(() => import('./components/Dashboard'))
const NonCommercialPortfolioApplication = lazy(() => import('./pages/NonCommercialPortfolioApplication'))

const App = () => {
  const [{ user, error: authError, auth }] = useSession()

  const { data: menuItems, isLoading } = useQuery('NavigationMenu', () => getMenu(), {
    ...queryOptions,
    enabled: !!user.UserId,
  })

  const permissionAccessError = authError === 'unauthenticated'

  return (
    <Layout>
      <Router>
        <Helmet title={settings.appTitle} />
        <ErrorSummaryProvider>
          <Header fetchingMenu={isLoading} menuItems={menuItems} />

          <Container>
            <Banner
              active={permissionAccessError}
              heading='There was a problem with your permissions'
              type='error'
              data-testid='unauthenticated-banner'
              role='alert'
            >
              If you think you have the correct permissions and the issue persists, attempt a{' '}
              <a
                href={getLogoutUrl(auth.accessToken)}
                onClick={(evt: React.MouseEvent) => {
                  evt.preventDefault()
                  const target = evt.target as HTMLAnchorElement
                  AuthService.logout(target.href)
                }}
              >
                logout
              </a>{' '}
              or{' '}
              <a href='https://portal.nihr.ac.uk/help?app=CPMS' rel='external'>
                contact support
              </a>
            </Banner>

            {!user.UserId && (
              <Card>
                <Loader message='Logging In...'></Loader>
              </Card>
            )}

            {!permissionAccessError && user.UserId && (
              <Suspense
                fallback={
                  <Card>
                    <Loader message='Loading...' />
                  </Card>
                }
              >
                <Switch>
                  <Route path='/error' exact component={Error} />
                  <Route path='/my-service-applications' exact component={Dashboard} />
                  <Route path='/non-commercial-portfolio' exact component={NonCommercialPortfolioApplication} />
                  <Route
                    path='/'
                    exact
                    render={({ location }) => {
                      const { state } = queryString.parse(location.search.substring(1))
                      if (state) {
                        return <Redirect to={state as string} />
                      }
                      return <Home />
                    }}
                  />
                  <Route
                    render={() => {
                      return (
                        <ErrorSummaryProvider>
                          <Card className='my-3 mb-0'>
                            <Heading as='h2' size='xl'>
                              Apply for a service for a new study
                            </Heading>
                          </Card>
                          <Breadcrumbs />
                          <Route path='/guidance' exact component={Guidance} />
                          <Route path='/study-type' exact component={ServiceStudy} />
                          <Route path='/lead-administration' exact component={LeadAdminstration} />
                          <Route path='/available-services' exact component={AvailableServices} />
                          <Route path='/create-new-study' exact component={CreateNewStudy} />
                        </ErrorSummaryProvider>
                      )
                    }}
                  />
                </Switch>
              </Suspense>
            )}
          </Container>
          <Footer />
        </ErrorSummaryProvider>
      </Router>
    </Layout>
  )
}

export default App
