import { Roles, StudyRoles, User } from '../../../models/Auth'

export const isStudyRepresentativeAndAccordSpecialist = (user: User) => {
  return (
    user.StudyRoles.includes(StudyRoles.StudyRepresentative) &&
    user.Roles.includes(Roles.AcoRDSpecialist)
  )
}

export default isStudyRepresentativeAndAccordSpecialist
