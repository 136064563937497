import Screens from './screens'
import Entries from './entries'

const screenNameMapping: { [key in Screens]: { screenTagId: string; entries: Entries[] } } = {
  // SoECAT Screens
  [Screens.GUIDANCE_SCREEN]: {
    screenTagId: 'soecatGuidanceScreen',
    entries: [Entries.GUIDANCE_SCREEN_RICH_TEXT],
  },

  // SIM Screens
  [Screens.SIM_GUIDANCE_SCREEN]: {
    screenTagId: 'simGuidanceScreen',
    entries: [Entries.SIM_GUIDANCE_SCREEN_RICH_TEXT],
  },
  // create new study
  [Screens.SIM_CREATE_STUDY_CONTACT_SCREEN]: {
    screenTagId: 'simCreateStudyContactScreen',
    entries: [Entries.SIM_CREATE_STUDY_CONTACT_RICH_TEXT],
  },
  // create new study not contact
  [Screens.SIM_CREATE_STUDY_NON_CONTACT_SCREEN]: {
    screenTagId: 'simCreateStudyNonContact',
    entries: [Entries.SIM_CREATE_STUDY_NON_CONTACT_RICH_TEXT],
  },
  [Screens.SIM_CREATE_STUDY_HELPER_TEXT]: {
    screenTagId: 'sim81HelperText',
    entries: [Entries.SIM_CREATE_STUDY_HELPER_TEXT_RICH_TEXT],
  },
  [Screens.SIM_AVAILABLE_SERVICES_NPA_AGREEMENT_RICH_SCREEN]: {
    screenTagId: 'simAvailableServicesNPAAgreementScreen',
    entries: [Entries.SIM_AVAILABLE_SERVICES_NPA_AGREEMENT_RICH_TEXT],
  },
  [Screens.SIM_SELECT_STUDY_TYPE]: {
    screenTagId: 'simSelectStudyType',
    entries: [Entries.SIM_SELECT_STUDY_TYPE_COMMERCIAL_LABEL, Entries.SIM_SELECT_STUDY_TYPE_NON_COMMERCIAL_LABEL],
  },
  [Screens.SIM_MANAGE_STUDY_CONTACTS_SCREEN]: {
    screenTagId: 'simManageStudyContactsScreen',
    entries: [Entries.SIM_MANAGE_STUDY_CONTACTS_GUIDANCE_TEXT],
  },
  [Screens.SIM_HOME_SCREEN]: {
    screenTagId: 'simHomeScreen',
    entries: [
      Entries.SIM_HOME_APPLY_BUTTON_TEXT,
      Entries.SIM_HOME_GUIDANCE_TEXT,
      Entries.SIM_HOME_NEWS_BANNER,
      Entries.SIM_HOME_WARNING_BANNER,
    ],
  },
  [Screens.SIM_AVAILABLE_SERVICES_SCREEN]: {
    screenTagId: 'simAvailableServicesScreen',
    entries: [Entries.SIM_AVAILABLE_SERVICES_NPA_GUIDANCE_TEXT, Entries.SIM_AVAILABLE_SERVICES_SOECAT_GUIDANCE_TEXT],
  },
}

export default screenNameMapping
