
import { api } from '../helpers/api-axios'

import { GetMenuResponse } from '../models/MenuResponse.interface'

export const getMenu = async () => {
  const response = await api.get<GetMenuResponse>(`${process.env.REACT_APP_BASE_API_URL}/sim/v1/getmenu`)

  return response.data
}

export default { getMenu }
