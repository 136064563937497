import { Roles, StudyRoles, User } from '../../../models/Auth'

export const viewChangeComments = (user: User) => {
  return (
    user.StudyRoles.includes(StudyRoles.StudyRepresentative) ||
    user.Roles.includes(Roles.AcoRDSpecialist) ||
    user.Roles.includes(Roles.TriageOfficer)
  )
}

export default viewChangeComments
