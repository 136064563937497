import { AxiosResponse } from 'axios'
import { CamundaResponse } from '../models/CamundaResponse.interface'
import { CamundaRequest } from '../models/CamundaRequest.interface'
import { log } from '../helpers/logger'
import { api } from '../helpers/api-axios'

const config = {
  timeout: 15000,
}

const responseBody = (response: AxiosResponse<CamundaResponse>) => response.data

const requests = {
  startProcess: (url: string, body: CamundaRequest) => api.post<CamundaResponse>(url, body, config).then(responseBody),
  completeTask: (url: string, body: CamundaRequest) => api.post<CamundaResponse>(url, body, config).then(responseBody),
  completeProcess: (url: string, body: CamundaRequest) =>
    api.post<CamundaResponse>(url, body, config).then(responseBody),
  getCurrentTask: (url: string) => api.get<CamundaResponse>(url, config).then(responseBody),
}

export enum CamundaProcesses {
  NPA_SERVICE_INPUT_VALUE = 'npa',
  SOECAT_SERVICE_INPUT_VALUE = 'soecat',
  CAMUNDA_OUTPUT_VARIABLE_NAME = 'simAvailableServices',
  SIM_AVAILABLE_SERVICES = 'Process_SimAvailableServices',
  SIM_AVAILABLE_SERVICES_FOR_STUDY = 'Process_SimAvailableServicesForStudy',
}

export const CamundaService = {
  startProcess: (post: CamundaRequest) =>
    requests.startProcess(`${process.env.REACT_APP_BASE_API_URL}/camunda/startProcess`, post),
  completeTask: (post: CamundaRequest) =>
    requests.completeTask(`${process.env.REACT_APP_BASE_API_URL}/camunda/completeTask`, post),
  completeProcess: (post: CamundaRequest) =>
    requests.completeProcess(`${process.env.REACT_APP_BASE_API_URL}/camunda/completeProcess`, post),
  getCurrentTask: (businessKey: string) => {
    log.debug(
      'Fetching camunda getTaskByBusinessKey',
      `${process.env.REACT_APP_BASE_API_URL}/camunda/getTaskByBusinessKey/${businessKey}`,
    )
    return requests.getCurrentTask(`${process.env.REACT_APP_BASE_API_URL}/camunda/getTaskByBusinessKey/${businessKey}`)
  },
}

export default CamundaService
