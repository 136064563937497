import { api } from '@cpms/common/helpers/api-axios'
import { SysRefResponse } from '../models/SysRefResponse.interface'

const sysRefResponseMapper = (data: Array<SysRefResponse>) =>
  data.map(({ Id, Name, Description }) => ({
    id: Id,
    name: Name,
    description: Description,
  }))

export async function fetchSysRefUkCountry() {
  const response = await api.get<Array<SysRefResponse>>(
    `${process.env.REACT_APP_BASE_API_URL as string}/${process.env.REACT_APP_SYS_REF_DATA as string}`,
  )
  return sysRefResponseMapper(response.data)
}
