import { LiveMessage } from 'react-aria-live'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import Entries from '@config/entries'
import Screens from '@config/screens'
import BottomController from '@cpms/common/components/BottomController'
import Card from '@cpms/common/components/Card'
import { ErrorMessageWithFocus, ErrorProps } from '@cpms/common/components/ErrorMessage'
import { RadioButton } from '@cpms/common/components/Forms'
import { queryOptions } from '@cpms/common/config/settings'
import CamundaService, { CamundaProcesses } from '@cpms/common/services/CamundaService'
import { useErrorSummary } from '@cpms/common/context/ErrorSummaryContext'

import useContentful from '@hooks/useContentful'
import { useContext, useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import ServiceAgreement from '../components/ServiceAgreement'
import settings, { initialErrors, services } from '../config/settings'
import { StoreContext } from '../context/Provider'
import { fetchSysRefUkCountry } from '../requests/SysRef'

const AvailableServices = () => {
  const history = useHistory()
  const errorMessages = useErrorSummary()
  const [items, isLoading] = useContentful(Screens.SIM_AVAILABLE_SERVICES_SCREEN)
  const { form, dispatch } = useContext(StoreContext)

  const [inputValue, setInputValue] = useState<string>(form.services)
  const [agreement, setAgreement] = useState<boolean>(form.agreed)
  const [simAvailableServicesValue, setSimAvailableServicesValue] = useState<string>()

  useEffect(() => {
    // If any persisted data is lost, restart the application
    if (form.studyTypeId === 0) {
      history.push('/guidance')
    }
  }, [form.studyTypeId])

  const { data: nations } = useQuery('SysRefUkCountry', fetchSysRefUkCountry, queryOptions)

  const handleChange = (choice: any) => {
    setInputValue(choice)
    setAgreement(false)
    errorMessages.dispatch({ type: 'clear' })
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    errorMessages.dispatch({ type: 'clear' })
    if (!inputValue) {
      errorMessages.dispatch({
        type: 'add',
        payload: {
          message: 'You must select an option for the following questions before you continue:',
          errors: [
            {
              formInputId: 'pa-radio-soecat',
              label: 'Available services',
            },
          ],
        },
      })
      errorMessages.dispatch({ type: 'focus' })
    } else if (inputValue === services.NPA_SERVICE_INPUT_VALUE && agreement === false) {
      errorMessages.dispatch({
        type: 'add',
        payload: {
          message: 'Confirm that your study meets the criteria to apply for this service',
          errors: [
            {
              formInputId: 'confirm-1',
              label: 'I can confirm my study meets the above criteria',
            },
          ],
        },
      })
      errorMessages.dispatch({ type: 'focus' })
    } else {
      errorMessages.dispatch({ type: 'clear' })
      dispatch({ type: 'FORM', payload: { services: inputValue, agreed: form.agreed } })
      history.push('/create-new-study')
    }
  }

  useEffect(() => {
    if (!nations) return

    const studyType = form.studyTypes[form.studyTypeId].name.toLowerCase()
    const leadAdmin = nations.find((nation) => nation.id === form.leadAdministrationId)?.description || ''

    CamundaService.startProcess({
      input: {
        processName: CamundaProcesses.SIM_AVAILABLE_SERVICES,
        variables: {
          studyType: { value: studyType, type: 'string' },
          leadAdmin: { value: leadAdmin.toLowerCase(), type: 'string' },
        },
      },
      output: {
        variableNames: [CamundaProcesses.CAMUNDA_OUTPUT_VARIABLE_NAME],
      },
    }).then((camundaResponse) => {
      const simAvailableServices = camundaResponse.variables.find(
        (variable) => variable.name === 'simAvailableServices',
      )?.value as string

      setSimAvailableServicesValue(simAvailableServices)
      CamundaService.completeProcess({
        input: {
          businessKey: camundaResponse.businessKey,
        },
      })
    })
  }, [form, nations])

  const chosenNation = (nations && nations.find((nation) => nation.id === form.leadAdministrationId)?.description) || ''

  const hasAvailableServiceError =
    errorMessages.state.message === 'You must select an option for the following questions before you continue:'
  const hasServiceAgreementError =
    errorMessages.state.message === 'Confirm that your study meets the criteria to apply for this service'

  return (
    <>
      <Helmet title={`${settings.appTitle} - Available services`} />
      <section>
        <LiveMessage
          aria-live='assertive'
          message={`
          Apply for a service for a new study
          Your study is ${
            form.studyTypeId && form.studyTypes[form.studyTypeId].name
          } and the lead administration is ${chosenNation}.
          Select the service you would like to apply for first. The other services will be available to complete on your service applications dashboard.
          If you are not sure whether your study is a non-commercial or commercial study, please contact us at support my study@n i h r.ac.uk for advice before continuing your application
        `}
          clearOnUnmount
        />
        <ErrorMessageWithFocus errors={errorMessages.state} />
        <form onSubmit={handleSubmit}>
          <Card>
            <h1 className='text-xl'>Available services</h1>
            <div role='radiogroup' aria-labelledby='studyType-label'>
              <div id='studyType-label'>
                <p>
                  Your study is {form.studyTypeId && form.studyTypes[form.studyTypeId].name} and the lead administration
                  is {chosenNation}
                </p>
                <p>
                  Select the service you would like to apply for first. The other services will be available to complete
                  on your service applications dashboard.
                </p>
              </div>
              {simAvailableServicesValue?.includes('soecatRadioButtonId') && (
                <div>
                  {hasAvailableServiceError && <div className='err'>Invalid selection</div>}
                  <RadioButton
                    label='Schedule of events cost attribution template(SoECAT)'
                    description={
                      isLoading
                        ? 'Loading...'
                        : items[Entries.SIM_AVAILABLE_SERVICES_SOECAT_GUIDANCE_TEXT]?.fields.richTextLinksOnly
                    }
                    value={services.SOECAT_SERVICE_INPUT_VALUE}
                    setValue={handleChange}
                    aria-label='Select SoECAT'
                    aria-required='true'
                    error={hasAvailableServiceError}
                    data-testid='SoECAT'
                    selected={inputValue}
                  />
                </div>
              )}
              {simAvailableServicesValue?.includes('npaRadioButtonId') && (
                <div>
                  <RadioButton
                    label='Non-commercial Portfolio Application'
                    description={
                      isLoading
                        ? 'Loading...'
                        : items[Entries.SIM_AVAILABLE_SERVICES_NPA_GUIDANCE_TEXT]?.fields.richTextLinksOnly
                    }
                    value={services.NPA_SERVICE_INPUT_VALUE}
                    setValue={handleChange}
                    error={hasAvailableServiceError}
                    aria-label='Select NPA'
                    aria-required='true'
                    data-testid='NPA'
                    selected={inputValue}
                  />
                </div>
              )}
            </div>
            {inputValue === services.NPA_SERVICE_INPUT_VALUE && (
              <ServiceAgreement
                setValue={(value) => {
                  setAgreement(value)
                  errorMessages.dispatch({ type: 'clear' })
                }}
                error={hasServiceAgreementError}
                selected={agreement}
              />
            )}

            <div className='if-you-are-not-sure'>
              If you are not sure which service you require, please contact us at
              <a href='mailto:supportmystudy@nihr.ac.uk' className='referral' rel='noreferrer'>
                supportmystudy@nihr.ac.uk
              </a>
              for advice before continuing your application.
            </div>
          </Card>
          <BottomController />
        </form>
      </section>
    </>
  )
}

export default AvailableServices
