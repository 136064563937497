import Entries from '@config/entries'
import Screens from '@config/screens'
import { CheckboxGroup, Checkbox } from '@cpms/common/components/Forms'
import RichText from '@cpms/common/components/RichText'
import VisuallyHidden from '@cpms/common/components/VisuallyHidden/VisuallyHidden'
import useContentful from '@hooks/useContentful'

const ServiceAgreement = ({
  setValue,
  error = false,
  selected,
}: {
  setValue: (value: boolean) => void
  error: boolean
  selected: boolean
}) => {
  const [items, isLoading] = useContentful(Screens.SIM_AVAILABLE_SERVICES_NPA_AGREEMENT_RICH_SCREEN)
  return (
    <div className='my-10 bg-gray-100 p-10'>
      <h2>
        <strong>
          Which studies can apply for NIHR RDN support through the Non-commercial Portfolio Application service?
        </strong>
      </h2>
      <div className='scrollBar bg-white p-5'>
        {isLoading && <h1>Loading...</h1>}
        {!isLoading && (
          <RichText
            richTextDocument={items[Entries.SIM_AVAILABLE_SERVICES_NPA_AGREEMENT_RICH_TEXT]?.fields.richText}
          ></RichText>
        )}
      </div>
      <CheckboxGroup
        error={!!error}
        id='confirm'
        name='confirm'
        label={<VisuallyHidden> I can confirm my study meets the above criteria</VisuallyHidden>}
      >
        <Checkbox checked={selected} onChange={() => setValue(!selected)}>
          I can confirm my study meets the above criteria
        </Checkbox>
      </CheckboxGroup>
    </div>
  )
}

export default ServiceAgreement
