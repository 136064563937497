import { LiveMessage } from 'react-aria-live'
import { Helmet } from 'react-helmet'
import { useErrorSummary } from '@cpms/common/context/ErrorSummaryContext'
import Card from '@cpms/common/components/Card'
import BottomController from '@cpms/common/components/BottomController'
import { ErrorMessageWithFocus, ErrorProps } from '@cpms/common/components/ErrorMessage'
import { RadioButton, RadioGroup } from '@cpms/common/components/Forms'
import { FC, useContext, useRef, useState } from 'react'
import Entries from '../config/entries'
import settings, { initialErrors, services } from '../config/settings'
import Screens from '../config/screens'
import { StoreContext } from '../context/Provider'

import useContentful from '../hooks/useContentful'

declare let process: {
  env: {
    REACT_APP_205_REDIRECT: string
  }
}

const ServiceStudy: FC = ({ history }: any) => {
  const { form, dispatch } = useContext(StoreContext)
  const [inputValue, setInputValue] = useState<number>(form.studyId)
  const errorMessages = useErrorSummary()

  // ref hooks
  const inputRef = useRef(null)
  const errorRef = useRef(null)

  // error handler
  const [errors, setErrors] = useState<ErrorProps>({ ...initialErrors })

  // contentful hooks
  const [items, isLoading] = useContentful(Screens.SIM_SELECT_STUDY_TYPE)

  const handleChange = (choice: any): void => {
    setInputValue(choice)
    setErrors({ ...errors, error: false })
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!inputValue) {
      setErrors({ ...errors, error: true, link: inputRef })
      errorMessages.dispatch({ type: 'focus' })
    } else if (inputValue === services.commercial) {
      setErrors({ ...errors, error: false })
      window.location.href = process.env.REACT_APP_205_REDIRECT
    } else if (inputValue === services.nonCommercial) {
      setErrors({ ...errors, error: false })
      dispatch({ type: 'FORM', payload: { studyTypeId: inputValue } })
      history.push('/lead-administration')
    }
  }

  return (
    <>
      <Helmet title={`${settings.appTitle} - Select study type`} />

      <section ref={errorRef}>
        <LiveMessage
          aria-live='assertive'
          message={
            'Apply for a service for a new study - Please select the type of study you are applying for a service for'
          }
          clearOnUnmount
        />

        <ErrorMessageWithFocus
          errors={{
            error: errors.error,
            message: 'You must select an option for the following questions before you continue:',
            label: 'Select study type',
            description: '',
            link: inputRef,
          }}
        />

        <form onSubmit={handleSubmit}>
          <Card>
            <h1 className='text-xl' data-testid='selectStudyType'>
              Select study type
            </h1>
            <RadioGroup id='studyType' label='Please select the type of study you are applying for a service for'>
              {errors.error && <div className='err'>Invalid selection</div>}

              <RadioButton
                name='study-type'
                label='Non-commercial'
                description={
                  !isLoading &&
                  (items[Entries.SIM_SELECT_STUDY_TYPE_NON_COMMERCIAL_LABEL]?.fields.shortText || 'Loading...')
                }
                value={services.nonCommercial}
                setValue={handleChange}
                error={errors.error}
                selected={inputValue}
                data-testid='nonCommercial'
                ref={inputRef}
              />

              <RadioButton
                name='study-type'
                label='Commercial'
                description={
                  !isLoading &&
                  (items[Entries.SIM_SELECT_STUDY_TYPE_COMMERCIAL_LABEL]?.fields.shortText || 'Loading...')
                }
                value={services.commercial}
                setValue={handleChange}
                error={errors.error}
                selected={inputValue}
                data-testid='commercial'
              />
            </RadioGroup>
            <div data-testid='help-text' className='if-you-are-not-sure'>
              If you are not sure whether your study is a non-commercial or commercial study, please contact us at
              <a href='mailto:supportmystudy@nihr.ac.uk' className='referral' rel='noreferrer'>
                supportmystudy@nihr.ac.uk
              </a>
              for advice before continuing your application
            </div>
          </Card>
          <BottomController />
        </form>
      </section>
    </>
  )
}

export default ServiceStudy
