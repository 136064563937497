enum Entries {
  GUIDANCE_SCREEN_RICH_TEXT,
  SIM_GUIDANCE_SCREEN_RICH_TEXT,
  SIM_SELECT_STUDY_TYPE_COMMERCIAL_LABEL,
  SIM_SELECT_STUDY_TYPE_NON_COMMERCIAL_LABEL,
  SIM_CREATE_STUDY_CONTACT_RICH_TEXT,
  SIM_CREATE_STUDY_NON_CONTACT_RICH_TEXT,
  SIM_CREATE_STUDY_HELPER_TEXT_RICH_TEXT,
  SIM_AVAILABLE_SERVICES_NPA_AGREEMENT_RICH_TEXT,
  SIM_AVAILABLE_SERVICES_NPA_GUIDANCE_TEXT,
  SIM_AVAILABLE_SERVICES_SOECAT_GUIDANCE_TEXT,
  SIM_MANAGE_STUDY_CONTACTS_GUIDANCE_TEXT,
  SIM_HOME_GUIDANCE_TEXT,
  SIM_HOME_APPLY_BUTTON_TEXT,
  SIM_HOME_NEWS_BANNER,
  SIM_HOME_WARNING_BANNER,
}

export default Entries
