import { ErrorProps } from '@cpms/common/components/ErrorMessage'
import { User } from '@cpms/common/models/Auth'

export const initialErrors: ErrorProps = {
  error: false,
  label: '',
  message: 'You must correct the following fields before you continue:',
  description: '',
  multipleErrors: [],
}

export const notPublicUserError: ErrorProps = {
  error: true,
  label: '',
  message: 'Authorisation error',
  description:
    'Your account does not have the required permissions to use this application. If you think this is incorrect please contact support.',
}

export const authError: ErrorProps = {
  error: true,
  label: '',
  message: 'Authentication error',
  description: 'An error occurred while logging you in. If the issue persists contact support.',
}

export enum services {
  NPA_SERVICE_INPUT_VALUE = '7',
  SOECAT_SERVICE_INPUT_VALUE = '8',
  CAMUNDA_OUTPUT_VARIABLE_NAME = 'simAvailableServices',
  CAMUNDA_PROCESS_SIM_AVAILABLE_SERVICES = 'Process_SimAvailableServices',
  commercial = 1,
  nonCommercial = 2,
}
export const initialUser: User = {
  FirstName: '',
  LastName: '',
  Email: '',
  UserId: '',
  PersonId: 0,
  StudyId: 0,
  StudyRoles: [],
  Roles: [],
}

export default {
  appTitle: 'CPMS – SIM',
  studiesPerPage: 20,
  createStudyPages: ['guidance', 'study-type', 'lead-administration', 'available-services', 'create-new-study'],
}
