import * as contentful from 'contentful'
import { Entry } from 'contentful'
import { useEffect, useState } from 'react'
import Entries from '../config/entries'
import entriesMapping from '../config/entries-mapping'
import Screens from '../config/screens'
import screenNameMapping from '../config/screens-mapping'

declare let process: {
  env: {
    REACT_APP_CONTENTFUL_SPACE_ID: string
    REACT_APP_CONTENTFUL_ACCESS_TOKEN: string
    REACT_APP_CONTENTFUL_ENVIRONMENT: string
  }
}

const client = contentful.createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT, // defaults to 'master' if not set
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
})

// Takes a screen and fetches all content for that screen from contentful
function useContentful(screen: Screens) {
  const [isLoading, setIsLoading] = useState(true)
  // TODO use EntryCollection instead of any

  const [items, setItems] = useState<any>()

  // Notice the first arg is our function and second arg is empty array- this means this
  // function will only run once on load, similar to the old "componentDidMount()"
  useEffect(() => {
    client
      .getEntries({
        'metadata.tags.sys.id[in]': screenNameMapping[screen].screenTagId,
      })
      .then((entries) => {
        // wrangle the contentful response into developer friendly object.
        let initialEntries: { [key in Entries]?: Entry<any> } = {}
        initialEntries = entries.items.reduce((accumulator, entry) => {
          screenNameMapping[screen].entries.forEach((entryId) => {
            entry.metadata.tags.forEach((tagLink) => {
              if (tagLink.sys.id === entriesMapping[entryId]) {
                if (!accumulator[entryId]) {
                  accumulator[entryId] = entry
                }
              }
            })
          })
          return accumulator
        }, initialEntries)
        setItems(initialEntries)
        setIsLoading(false)
      })
      .catch(console.error)
  }, [screen])

  return [items, isLoading]
}

export default useContentful
