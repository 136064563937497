import { useLocation } from 'react-router-dom'
import cx from 'classnames'
import settings from '@app/config/settings'

import './Breadcrumbs.styles.scss'

const Breadcrumbs = () => {
  const location = useLocation()
  const { pathname } = location
  const splitLocation = pathname.split('/')[1]

  return (
    <nav className='breadcrumbs mb-3 bg-white px-6' data-testid='breadcrumbs'>
      <ol className='flex flex-wrap'>
        {settings.createStudyPages.map((page) => {
          const isActive = splitLocation === page
          return (
            <li
              data-active
              className={cx('breadcrumbs-item text-sm uppercase', {
                'text-blue': isActive,
                'text-charcoal': !isActive,
              })}
              key={page}
            >
              {page.replace(/-/g, ' ')}
            </li>
          )
        })}
      </ol>
    </nav>
  )
}

export default Breadcrumbs
